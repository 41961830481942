.products-table-wrapper {
  margin: 10px 0;

  .header-wrapper {
    .desc {
      width: 55%;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  .loading-placeholder {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    .skeleton-wrap {
      margin-left: 32px;
      width: 100%;
    }
  }

  .product-name {
    text-decoration: underline;
  }

  .product-code {
    font-size: 12px;
    color: grey;
  }

  .product-current-price {
    cursor: pointer;
    text-decoration: underline;
  }

  .empty-price {
    cursor: pointer;
  }

  .product-attr-image {
    width: 50px;
    height: 50px;
    cursor: pointer;

    img {
      object-fit: cover;
    }
  }

  .product-attr-name {
    .sku {
      font-size: 12px;
      color: #666;
    }
  }

  .product-attr-price {
    .old {
      text-decoration: line-through;
    }
  }

  .ant-spin-nested-loading > div > .ant-spin {
    max-height: 100%;
  }

  .ant-spin-text {
    color: #bd0000;
  }
}
