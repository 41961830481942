$color-red: #bd0000;
$color-red2: #ed253d;
$color-green: #35b420;
$color-blue: rgb(48, 138, 221);
$color-white: #fff;
$color-black: #000;
$color-orange: #ff9901;
$color-mid-gray6: #666;
$color-grey: #eee;
$color-white-grey9: #f9f9f9;
$color-mid-gray3: #333;
$color-white-opacity-60: rgba(255, 255, 255, 0.6);
