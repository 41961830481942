.searched-table {
  .row-disable {
    display: none;
  }

  .product-name {
    text-decoration: unset;
    cursor: unset;
    min-width: 170px;
    cursor: pointer;
  }

  .product-price {
    color: #db0000;
  }

  .old-price {
    text-decoration: line-through;
  }
}

.hidden {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.loading-wrapper {
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}