@import '../../../../assets/sass/colors';
.member-order {
    color: #000;
    .order-number {
        font-size: 18px;
    }
}
.member-order {
    margin-bottom: 30px;
    border-radius: 4px;
    padding: 30px 0;
    border: 1px solid #eee;
    .grp-icons {
        display: inline-block;
    }
    .payment-status {
        padding: 2px 5px;
        border-radius: 3px;
        color: #fff;
        background-color: #bd0000;
        font-size: 14px;
        &.success {
            color: rgb(255, 255, 255);
            background-color: #35b420;
        }
        &.transfer,
        &.installment {
            color: rgb(255, 255, 255);
            background: rgb(48, 138, 221);
        }
    }
    .title {
        margin-bottom: 20px;
        font-size: 24px;
        @media (min-width: 1921px) {
            font-size: 28px;
        }
    }
    table {
        width: 100%;
        &.tbl-order-no {
            margin-bottom: 10px;
        }
    }
    .ord-sts {
        text-align: center;
        height: 32px;
        line-height: 32px;
        text-transform: uppercase;
        letter-spacing: 1pt;
        margin-bottom: 20px;
        color: #fff;
        font-family: Arial, Helvetica, sans-serif;
        @media (min-width: 1921px) {
            height: 42px;
            line-height: 42px;
        }
        &.processing {
            color: $color-white;
            background: $color-blue;
        }
        &.preorder,
        &.reception-order,
        &.support {
            border: 1px solid $color-blue;
            color: $color-blue;
        }
        &.order {
            color: $color-white;
            background: $color-red;
        }
        &.done {
            color: $color-white;
            background-color: #35b420;
        }
        &.delivered,
        &.packed,
        &.shipping {
            background-color: $color-orange;
            color: $color-white;
        }
        &.cancel {
            background-color: $color-mid-gray6;
            color: $color-white;
        }
        &.deposit {
            color: $color-red;
            border: 1px solid $color-red;
        }
    }
    .customer-info {
        margin-bottom: 30px;
        border-bottom: 1px solid #494949;
        .table {
            td {
                border: none;
                padding: 5px 0;
                &:first-child {
                    width: 180px;
                }
            }
        }
    }
    .order-note {
        margin-bottom: 30px;
    }
    .order-table {
        .table-responsive {
            table {
                border-top: 3px solid #333;
                border-bottom: 2px solid #eee;
                margin-top: 3px;
                th {
                    font-weight: bold;
                    padding: 20px 5px;
                }
                .trline {
                    td {
                        padding: 15px 5px;
                        border-top: 1px solid #eee;
                        span {
                            display: block;
                            &.old {
                                .txt-price {
                                    text-decoration: line-through;
                                }
                            }
                            &.symb {
                                margin-left: 5px;
                            }
                            span {
                                display: inline-block;
                            }
                        }
                    }
                }
            }
        }
    }
    .order-total {
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: 1px solid #eee;
        p {
            margin-bottom: 5px;
        }
        table {
            td {
                padding: 3px;
                span {
                    font-size: 12px;
                    @media (min-width: 1921px) {
                        font-size: 14px;
                    }
                }
            }
            .all-total {
                td {
                    font-weight: bold;
                    padding-top: 15px;
                }
            }
        }
    }
    .tbl-payment-method {
        img {
            height: 18px;
            width: auto;
            vertical-align: -4px;
            @media (min-width: 1921px) {
                height: 20px;
            }
        }
    }
}
