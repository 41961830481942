.review-page__tabs {
  font-size: 14px;
  .status {
    position: relative;
    cursor: pointer;
    text-transform: capitalize;
    &.active {
      font-weight: 700;
      text-decoration: underline;
    }
  }
  .symb {
    padding: 0 10px;
    color: #494949;
  }
  .pending {
    color: #ff8b00;
  }
  .published {
    color: #319f50;
  }
  .locked {
    color: #494949;
  }
}
.review-wrap {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-family: Roboto, sans-serif;
  .count-review {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000;
    .count {
      color: #db0000;
      margin-right: 5px;
    }
  }
}
.review-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.review-item {
  padding: 16px;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  font-weight: 500;
  color: #0a0a0a;
  font-size: 14px;
  line-height: 22px;
  .lbl {
    color: #777;
  }
  .date {
    .lbl {
      color: #494949;
    }
  }
  .review-col {
    display: flex;
    flex-direction: column;
    gap: 12px;
    @media (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .rating {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 24px;
    color: #ddd;
    .red {
      color: #ba2a2a;
    }
  }
  .images-lst {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    a {
      cursor: pointer;
      background-color: #d9d9d9;
      width: 50px;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
    }
  }
  .sharing-lst {
    li {
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.review-item {
  font-size: 12px;
  .desc {
    font-size: 16px;
    padding: 16px;
    background: #f4f4f4;
    border-radius: 4px;
  }
  .status {
    padding: 3px 16px;
    border-radius: 4px;
    color: #fff;
    font-size: 11px;
    line-height: 14px;
    text-align: center;
    height: auto;
    pointer-events: none;
    text-transform: capitalize;
    span {
      min-height: auto;
    }
  }
  .action {
    height: 42px;
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    i {
      font-size: 20px;
    }
  }
}
.review-main-content {
  position: relative;
  padding: 30px;
  border-radius: 10px;
  background-color: #fff;
  .review-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    transition: opacity 0.15s ease-in;
    opacity: 0;

    &.show {
      opacity: 1;
      z-index: 10;
    }
  }
}
// CSS RESPONSIVE
@media (max-width: 1820px) {
  .prod-review-item {
    .col-right {
      .review-col {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
@media (max-width: 1440px) {
  .prod-review-item {
    .col-left, .col-right {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-left {
      margin-bottom: 20px;
    }
    .col-right {
      .review-col {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%;
        margin-bottom: 0;
      }
    }
  }
}
@media (max-width: 1366px) {
  .review-item {
    .col-left, .col-right {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-left {
      margin-bottom: 20px;
    }
  }
}