@import '../variables';

$_shape: 5px;
$_fs: 1rem;

$_fs-sm: ($_fs * 0.9);
$_lh-sm: $module-rem * 2 / $_fs-sm;
$_padding-v-sm: ($module-rem / 2);

// size lg
$_fs-lg: ($_fs * 1.3);
$_lh-lg: $module-rem * 2 / $_fs-lg;
$_padding-v-lg: $module-rem * 1.5;

$_bg: (default: rgba(#fff, 0.08),
  hover: rgba(#fff, 0.08),
  focus: #fff,
  disabled: rgba(#ccc, 0.1),
);

$_border: (default: rgba(#4a505c, 0.08),
  hover: rgba(#4a505c, 0.08),
  focus: rgba(#ccc, 1),
  disabled: rgba(#ccc, 0.5),
);

$_color: (default: #4a505c,
  hover: #4a505c,
  focus: #4a505c,
  disabled: #bdbdbd,
);

html {

  .ant-input-password,
  .ant-input-affix-wrapper {
    box-shadow: none;
    border-color: map_get($_border, default);
    background-color: map_get($_bg, default);
    border-radius: $_shape;
    height: 48px;
    color: map_get($_color, default);

    .ant-input {
      height: 39px;
    }

    &:hover {
      cursor: text;
      border-color: unset;
      background-color: map_get($_bg, focus) !important;
      border-color: map_get($_border, focus) !important;
    }

    &:focus {
      background-color: map_get($_bg, focus) !important;
      border-color: map_get($_border, focus) !important;
      box-shadow: none;
      color: map_get($_color, focus);
    }
  }

  .ant-input {
    box-shadow: none;
    border-color: map_get($_border, default);
    background-color: map_get($_bg, default);
    border-radius: $_shape;
    height: 48px;
    color: map_get($_color, default);

    &:hover {
      cursor: text;
      border-color: unset;
    }

    &:focus {
      background-color: map_get($_bg, focus) !important;
      border-color: map_get($_border, focus) !important;
      box-shadow: none;
      color: map_get($_color, focus);
    }

    &.ant-input-sm {
      border-radius: $_shape * 0.8;
      font-size: $_fs-sm;
      height: (3 * $module);
      line-height: $_lh-sm;
      padding-top: $_padding-v-sm;
      padding-bottom: $_padding-v-sm;
    }

    &.ant-input-lg {
      border-radius: $_shape * 1.5;
      font-size: $_fs-lg;
      height: (5 * $module);
      line-height: $_lh-lg;
      padding-top: $_padding-v-lg;
      padding-bottom: $_padding-v-lg;
    }
  }
}

.ant-cascader-picker-clear {
  border-radius: 50%;
}

.ant-form-item-label {
  font-weight: 600;
}

.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: map-get($_border, default);
}