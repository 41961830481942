@import 'assets/sass/variables';

.navbar {
  $_bg: #fff;
  $_color: $main-color;
  $_color: var(--main-color);

  background: $_bg;
  color: $_color;
  display: block;
  position: relative;
  transition: box-shadow 0.2s $animation;
  will-change: box-shadow;
  z-index: 990;

  .navbar-wrap {
    align-items: center;
    background: inherit;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    min-height: inherit;
    padding: 0 $module-rem;
    position: relative;

    >* {
      margin: 0 $module-rem;
    }

    .navbar-toggle {
      align-items: center;
      border: none;
      background: none;
      color: inherit;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      font-size: 22px;
      justify-content: center;
      margin: 0 $module-rem;
      height: 40px;
      width: 35px;

      span {
        background: main-palette(500);
        ;
        border-radius: 8px;
        display: block;
        height: 3px;
        margin: 4px 0;
        transition: background 0.2s $animation;
        width: 35px;
      }

      &:hover {
        span {
          background: main-palette(500);
          ;
        }
      }

      .navbar-close {
        color: #000;
        cursor: pointer;
        font-size: 30px;
        position: absolute;
        right: $module-rem;
        top: $module-rem * 1.5;
        width: auto !important;
        z-index: 999;
      }

      .add-patient {
        height: 100%;
        padding: ($module-rem * 2) ($module-rem * 4);
      }

      .assistant-menu {
        display: block;
        padding: 0 ($module-rem * 4) ($module-rem * 2);

        .link {
          color: #fff;
          display: block;
          font-weight: 700;
          opacity: 0.4;
          padding: ($module-rem / 2) 0;
          transition: opacity 0.2s $animation;

          .tc-icon {
            color: #fff;
            margin-right: $module-rem;
            padding: 0;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &.boxed {
    .navbar-wrap {
      background: none;
      max-width: $boxed-width;
      max-width: var(--boxed-width);
    }
  }

  &.horizontal-vertical {
    @media #{$max991} {
      display: flex;
      justify-content: flex-end;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: translateX(-$vertical-navbar-width);
      transition: transform 0.2s $animation;
      width: $vertical-navbar-width;
      will-change: transform, width;
      z-index: 1000;

      .navbar-wrap {
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        justify-content: flex-start;
        overflow: auto;
        padding: 0;
        width: $vertical-navbar-width;

        >* {
          margin: 0;
          width: 100%;
        }

        .logo {
          min-height: $navbar-height;
          background: #fff;
          box-shadow: 0 0 10px 0 rgba(#000, 0.15);
          z-index: 99;
        }
      }

      &.opened {
        transform: translateX(0);
      }

      &.loading {
        .main-menu {
          opacity: 0;
        }
      }
    }
  }

  &.vertical {
    background-image: linear-gradient(188deg, rgb(205, 84, 71), rgb(157, 92, 229) 65%);
    display: flex;
    justify-content: flex-end;
    height: 100%;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 0;
    transition: transform 0.2s $animation;
    width: $vertical-navbar-width;
    will-change: transform, width;
    -webkit-overflow-scrolling: touch;
    z-index: 1000;

    @media #{$max991} {
      transform: translateX(-$vertical-navbar-width);

      &.opened {
        .overlay {
          display: block;
        }
      }
    }

    .navbar-wrap {
      align-items: flex-start;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      padding: 0;
      width: $vertical-navbar-width;

      >* {
        margin: 0;
        width: 100%;
      }

      .logo {
        min-height: $navbar-height;
        background: #fff;
        box-shadow: 0 0 10px 0 rgba(#000, 0.15);
        z-index: 99;
      }
    }

    &.opened {
      transform: translateX(0);

      .overlay {
        display: block;
      }
    }
  }
}

.overlay {
  background: rgba(#000, 0.16);
  visibility: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  transform: translateX(0);
  transition: visibility 0.3s ease-in-out, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  z-index: 9996;

  @media #{$max991} {
    &.opened {
      opacity: 1;
      display: block;
      visibility: visible;
      transform: translateX($vertical-navbar-width);
      transition: visibility 0s linear, opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    }
  }
}