.select-variant-products-section {
  margin-top: 32px;

  .searched-product-wrap {
    padding: 16px 16px 16px 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-self: center;
  }

  .selected-product-col {
    border: 1px dashed #dddddd;
  }
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  padding-left: 48px;
  padding-right: 48px;
}