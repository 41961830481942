@import '../variables';

.ant-table-cell {
  .ant-table-wrapper {
    .ant-table {
      margin: 0 0 0 33px !important;
    }
  }
}

.ant-table-wrapper {
  overflow-x: hidden;

  .ant-table {
    overflow: auto;

    .ant-table-expanded-row {
      overflow: hidden;

      table tr th {
        border-radius: 0;
      }

      td+td {
        overflow: hidden;
        padding-top: $module-rem * 1.6;
        padding-bottom: $module-rem * 1.6;
      }
    }
  }

  .ant-table-thead {
    tr>th {
      background-color: rgba(29, 28, 28, 0.1);
      color: rgba(0, 0, 0, 0.8);
      padding: 0 ($module-rem * 1.6);
      height: 40px;
    }
  }

  .ant-table-tbody {
    >tr {
      >td {
        padding: $module-rem ($module-rem * 1.5);
      }

      &:hover:not(.ant-table-expanded-row) {
        >td {
          background-color: rgba(183, 183, 183, 0.06);
        }
      }
    }
  }
}