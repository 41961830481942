.selected-phone {
  .product-name {
    max-width: 300px !important;
  }
}
.products-table-wrapper {
  .product-price {
    color: #db0000;
  }
  .old-price {
    text-decoration: line-through;
  }
}