.row-dragging {
  background: #fafafa !important;
  border: 1px solid #ccc !important;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible !important;
}
