@import '../../../../../../assets/sass/colors';

.status {
    padding: 2px 16px;
    border-radius: 20px;
    &.processing {
        color: $color-white;
        background: $color-blue;
    }
    &.preorder, &.reception-order, &.support {
        border: 1px solid $color-blue;
        color: $color-blue;
    }
    &.order {
        color: $color-white;
        background: $color-red;
    }
    &.done {
        color: $color-white;
        background-color: #35b420
    }
    &.delivered, &.packed, &.shipping {
        background-color: $color-orange;
        color: $color-white;
    }
    &.cancel {
        background-color: $color-mid-gray6;
        color: $color-white;
    }
    &.deposit {
        color: $color-red;
        border: 1px solid $color-red;
    }
}
.order-item {
    a {
        text-decoration: none;
        &:hover {
            text-decoration: none;
            .title {
                text-decoration: underline;
            }
        }
    }
    .title {
        font-weight: bold;
    }
    .order-number {
        font-size: 14px;
    }
}
.orders-detail,
.order-item  {
    font-size: 16px;
}
.has-coupon {
    color: #666;
    font-size: 14px;
}