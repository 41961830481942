/**
 * CSS Rider Single page
 */
.rider-single-page-present {
    padding: 10px;
    background-color: #FFF;
    .block-item {
        height: 150px;
    }

}
.msg {
    font-size: 14px;
    color: #ed253d;
    display: block;
}
.form-error {
    .ant-form-item-label {
        > label {
            color: #ed253d;
        }
    }
    .ant-upload {
        &.ant-upload-drag {
            border-color: #ed253d;
        }
    }
}