.coupon-order-history {
  .coupon-order-history__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
  }

  .order-history__header {
    display: flex;
    align-items: center;
    gap: 24px;
    padding-bottom: 16px;
    border-bottom: 1px dashed #d9d9d9;

    .header__coupon-used-label,
    .header__coupon-statistic {
      font-size: 16px;
      font-weight: 700;
      line-height: 19px;
      color: #808080;
    }

    .header__coupon-statistic {
      color: #000000;

      > span {
        color: #159981;
      }
    }
  }

  .order-history__list-empty {
    margin: 24px 0px;
  }

  .order-history__list {
    position: relative;
    min-height: 200px;

    .list__loading {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 32px;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: rgba($color: #ffffff, $alpha: 0.6);
      transition: all 0.3s ease;

      &--show {
        opacity: 1;
      }

      &--hide {
        opacity: 0;
        z-index: -1;
      }
    }
  }

  .order-history__list-pagination {
    padding-top: 16px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  .order-history-item {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px 0px;
    border-bottom: 1px dashed #d9d9d9;

    .item__prefix {
      .item__symbol {
        color: #777777;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: underline;
        margin-right: 2px;
      }

      .item__prefix-code {
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        color: hsl(0, 0%, 0%);
      }
    }

    .item__order-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item_order-info-wrap {
        display: flex;
        align-items: center;

        .item__order-code {
          color: #777777;
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          margin-right: 4px;
        }

        .item__order-name {
          color: #000000;
          font-size: 14px;
          font-weight: 700;
          line-height: 16px;

          > span {
            color: #777777;
          }
        }
      }

      .item__view-order-detail {
        color: #777777;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        text-decoration: underline;
        cursor: pointer;
        user-select: none;
      }
    }

    .item__customer-info {
      display: flex;
      align-items: center;
      gap: 24px;

      .customer-info__name,
      .customer-info__phone,
      .customer-info__address {
        color: #777777;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }

      .customer-info__name {
        color: #000000;
      }
    }

    .item__process-info {
      display: flex;
      align-items: center;
      gap: 24px;

      .order-created,
      .process-info__label {
        color: #808080;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }

      .process-info__label {
        &--deposit,
        &--preorder {
          color: #006be3;
        }
        &--processing,
        &--order {
          color: #159981;
        }
        &--delivered {
          color: #ff6600;
        }
        &--cancel {
          color: #bd0000;
        }
      }
    }
  }
}
