// Reviews
.member-review-info {
    position: relative;
    .member-info-toolbar {
        position: relative;
        top: auto;
        right: auto;
    }
    .count-review {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #000;
        .count {
            color: #db0000;
            margin-right: 5px;
        }
    }
}
.member-review-content {
    position: relative;
    .user {
        display: none !important;
    }
}