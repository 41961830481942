.comments-page {
  font-size: 14px;

  .comment-list-wrapper {
    position: relative;

    .comment-empty {
      background-color: #fff;
      padding-top: 1.42857rem;
      padding-bottom: 1.42857rem;
      border-radius: 6px;
    }
  }

  .comment-list__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
  }

  hr {
    border-top: 1px solid #cecece;
  }

  .comment-number {
    border-right: 1px solid #cecece;
    height: 100%;
  }

  .comments-tabs-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .search-input {
      .input {
        background: #fff;
        color: #000;
        width: 350px;

        &::placeholder {
          color: #4a505c;
        }
      }
    }
    .sort-input {
      width: 200px;
    }

    .comments-tabs-sort {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }

    .comments-tabs {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .tab {
        background: #fff;
        border: 1px solid #4a505c;
        border-radius: 5px;
        height: 40px;
        padding: 0 1.5rem;
        margin-right: 10px;
        color: #4a505c;
        text-align: center;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all ease 0.2s;
        font-size: 18px;
        font-weight: bold;
        &:last-child {
          margin-right: 0;
        }

        &.active,
        &:hover {
          background: linear-gradient(250deg, #bd0000, #db0000 65%, #ff1717);
          border-color: #eb0a0a;
          color: #fff;
        }
      }
    }
  }

  .children-comments {
    .ant-row {
      &:first-child {
        border-top: unset !important;
        margin-top: 0 !important;
      }

      &:nth-child(n) {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid #cecece;
      }
    }
  }

  .base-comment-wrapper {
    border-right: 1px dashed #cecece;
    color: #808080;
    height: 100%;

    .commentor-name {
      padding: 0 10px;
      margin-left: 10px;
      border-left: 1px solid #cecece;
      font-weight: bold;
      color: #000;

      &.admin-name {
        color: #bd0000;
      }
    }

    .comment-text {
      color: #000;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .comment-tags {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;

      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        background: #eee;
        color: #000;
        border-radius: 3px;
        padding: 0 8px;
        height: 28px;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: all ease 0.2s;

        &:hover,
        &.active {
          color: #fff;
          background-color: #000;
        }
      }
    }
  }

  .commentor {
    height: 100%;

    .commentor-name {
      font-weight: bold;
      color: #000;
      margin-bottom: 10px;
    }

    .commentor-email {
      color: #808080;
      margin-bottom: 10px;
    }

    .btn-group {
      display: flex;
      flex-wrap: wrap;

      .ant-btn {
        height: 36px;
        padding: 0 1rem;
        margin-right: 4px;
        margin-top: 4px;
      }

      .denie-btn {
        &:hover,
        &:focus {
          background: #fff;
          color: #bd0000;
          border-color: #bd0000;
        }
      }
    }
  }
}
