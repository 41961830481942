.view-coupons-list {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 16px;

  .coupon-item {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid #eee;
    padding: 2px 4px;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;

    .coupon-item__copy {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #eee;
      border-radius: 16px;
      cursor: pointer;
      width: 32px;
      height: 32px;
    }
  }
}
