.product-item {
    cursor: pointer;
    margin-bottom: 20px;
    .ant-card {
        border-radius: 0;
        .ant-card-body {
            padding: 1rem;
        }
    }
    .status {
        top: -15px;
        left: 0;
        z-index: 1;
        position: absolute;
    }
    .title {
        padding-left: 14px;
        padding-right: 14px;
        height: 14px;
        .txt {
            font-size: 8px;
            font-weight: 600;
            line-height: 1;
            padding-top: 1px;
        }
    }
}
.product-item {
    .product-item__name {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 14px;
        line-height: 18px;
        height: 54px;
        text-align: center;
        margin-bottom: 1rem;
        font-weight: 500;
    }
    .product-item__image {
        padding-top: 100%;
        width: 100%;
        margin-bottom: 1.25rem;
        div {
            transition: all 0.35s ease;
        }
        .main-image {
            opacity: 1;
        }
        .second-image {
            opacity: 0;
        }
        @media (min-width: 1024px) {
            &:hover {
                &.product-item__image--multiple {
                    .main-image {
                        opacity: 0;
                    }
                    .second-image {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .product-item__image {
        .main-image, 
        .second-image {
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            position: absolute;
        }
    }
}
.product-item {
    .product-content {
        margin-bottom: 1rem;
    }
    .product-price {
        flex-direction: column;
        gap: 0.25rem;
        .cur-price {
            height: 1.5rem;
        }
        .price {
            font-size: 14px;
            font-weight: 700;
            line-height: 1.5;
            text-align: center;
            color: #000;
            @media (max-width:  380px) {
                font-size: 12px;
            }
        }
        .discount-percent {
            padding: 1px 2px;
            border-radius: 3px;
            border: 1px solid #86211F;
            .txt {
                color: #86211F;
                font-weight: 600;
                font-size: 11px;
            }
        }
        .old-price {
            text-decoration-line: line-through;
            font-weight: 500;
            font-size: 12px;
            height: 18px;
            color: #999;
        }
    }
}
.product-item {
    .rating {
        .star-item {
            line-height: 1;
            color: #ddd;
        }
    }
    .count {
        color: #ccc;
        margin-top: 2px;
        font-size: 12px;
        font-weight: 500;
    }
}
.product-item {
    @media (min-width: 1536px) {
        flex: 0 0 20% !important;
        max-width: 20% !important;
    }
}
.flex {
    display: flex;
}
.items-center {
    align-items: center;
}
.justify-center {
    justify-content: center;
}
.justify-between {
    justify-content: space-between;
}
.flex-wrap {
    flex-wrap: wrap;
}
.gap-2 {
    gap: 0.5rem;
}
.gap-1 {
    gap: 0.25rem;
}