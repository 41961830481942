.warranty-present {
    padding: 10px;
    background-color: #FFF;
    .block-item {
        height: 400px;
        &.seo {
            height: 100px;
        }
        &.h-130 {
            height: 127px;
        }
    }
}