/**
 * CSS GROUP SIZE
 */
.layout-list-pagination {
    display: flex;
    justify-content: flex-end;
}
.msg {
    font-size: 14px;
    color: #ed253d;
    display: block;
}
.form-error {
    .ant-form-item-label {
        > label {
            color: #ed253d;
        }
    }
    .ant-upload {
        &.ant-upload-drag {
            border-color: #ed253d;
        }
    }
    .ql-toolbar, .ql-container {
        &.ql-snow {
            border-color: #ed253d;
        }
    }
}
.form-size-filter {
    .ant-input {
        height: 38px;
    }
}