.menu-template {
  .menu-template__item-label {
    font-size: 20px;
    font-weight: 700;
  }
  .color-picker__swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    display: inline-block;
    cursor: pointer;
  }

  .color-picker__swatch-inner {
    width: 36px;
    height: 14px;
    border-radius: 2px;
  }

  .color-picker__popover {
    position: absolute;
    zindex: 2;
  }

  .color-picker__cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}
