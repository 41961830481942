// Importing main app variables
@import "variables";

@import "./fonts";
@import "./full-calendar";
@import "./structure";
@import "./typography";
@import "./helpers";

@import "./components/components";

@import "../css/icofont.min";
@import "./simple-line-icons";

.block-item {
  border: 1px dashed #d9d9d9;
  background: #f5f5f5;
  padding: 10px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  transition: border 0.2s;
  transition: ease-in 0.2s;
  cursor: pointer;

  &:hover {
    border: 3px dashed #000000;
    padding: 7px;
    background: #8f8f8f;
  }
}
.ant-pagination {
  .ant-pagination-prev,
  .ant-pagination-next,
  .ant-pagination-item {
    min-width: 2.14286rem;
    width: auto;
  }
}
