.rating-group {
    display: flex;
    align-items: center;
    gap: 8px;

    .rating-group__item {
        transition: all 0.1s ease;
        color: #dddddd;
        line-height: 1;
        > svg {
            width: 24px;
            height: 24px;
        }
    }
    &.view-only {
        pointer-events: none;
        cursor: default;
    }
}