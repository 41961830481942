.product-detail-present {
  background: #FFF;
  padding: 10px;

  .good-price {
    height: 200px;
    font-size: 20px;
  }

  .info-block {
    height: 350px;
    font-size: 20px;
  }
}