@import '../variables';

$_badge-bg: #bd0000;

.ant-badge {

  .ant-badge-count,
  .ant-badge-dot {
    background: $_badge-bg;
  }
}