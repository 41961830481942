@import 'assets/sass/variables';

.actions {
  align-items: center;
  display: flex;
  flex-grow: 2;
  justify-content: flex-end;
  margin: 0 !important;

  .item {
    margin: 0 $module-rem;
    cursor: pointer;

    &.blocked {
      cursor: default;
      pointer-events: none;
    }

    &.icon {
      transition: background 0.2s $animation, border 0.2s $animation, color 0.2s $animation,
        opacity 0.2s $animation;
    }

    .icon {
      &.log-out {
        margin: 0 !important;
        margin-left: auto !important;
        opacity: 0.2;
        color: #000 !important;
      }
    }

    .tc-badge {
      justify-content: center;
      font-size: 0.8em;
      min-width: 18px;
      height: 18px;
      padding-left: 2px;
      padding-right: 2px;
      position: absolute;
      right: -5px;
      text-align: center;
      top: -5px;
    }

    &:hover {
      .tc-icon {
        opacity: 1;
      }
    }

    &.active {
      .tc-icon {
        color: accent-palette(500);
        opacity: 1;
      }
    }
  }
}

.item-link {
  transition: color 0.2s $animation;

  &:hover {
    color: main-palette(500);
  }
}

.notification-icon {
  opacity: 0.5;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.dropdown-link-wrap {
  position: relative;
}

.dropdown-title {
  font-size: 1.1em;
  margin: 0;
  padding: $module-rem ($module-rem * 2);
}
.notify-review-item {
  text-decoration: none;
  .title {
    text-decoration: underline;
  }
  .prod-name {
    font-size: 11px;
  }
  &:hover {
    color: #4a505c !important;
    text-decoration: none;
    .title {
      color: #bd0000;
    }
  }
}
.notify-link, .notify-review-item {
  white-space: normal;
}