.product-review-wrap {
    .desc {
        p {
            margin-bottom: 5px;
        }
    }
    .review-form {
        border: 1px solid #cfcfcf;
        border-radius: 6px;
        padding: 16px;
    }
    .sl-status {
        text-transform: capitalize;
    }
    .review-main-content {
        padding: 0;
    }
    .review-img-lst {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 15px;
        .img-item {
            position: relative;
            img {
                object-fit: cover;
                width: 70px;
                height: 70px;
                object-fit: cover;
            }
        }
        .del-img {
            position: absolute;
            top: -5px;
            right: -5px;
            z-index: 1;
            cursor: pointer;
            color: #999;
            background-color: #fff;
            border: 1px solid #cfcfcf;
            font-size: 12px;
            border-radius: 10px;
            padding: 2px;
            &:hover {
                color: #fff;
                background-color: #000;
            }
        }
    }
}
// CSS RESPONSIVE
@media (max-width: 1440px) {
    .product-review-filter {
        .ant-col-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}
