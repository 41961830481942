@import '../variables';

.ant-timeline {
  .ant-timeline-item {
    &:not(:last-child) {
      padding-bottom: $module-rem * 3.2;
    }

    .ant-timeline-item-content {
      margin-left: $module-rem * 3;
    }

    .timeline-dot {
      align-items: center;
      display: flex;
      justify-content: center;
      color: #fff;
      font-size: 1.5em;
      border-radius: 50%;
      height: 35px;
      width: 35px;
    }
  }
  .ant-timeline-item-head:not(.ant-timeline-item-head-custom) {
    background-color: currentColor;
  }
  .ant-timeline-item-tail {
    border-color: rgba(#4a505c, 0.1);
  }
  .ant-timeline-item-head-gray {
    border-color: unset !important;
  }
}

.timeline-tag {
  color: #fff;
  font-size: 1.3em;
  min-width: 80px;
  padding: 4px 20px;
  text-align: center;
}
