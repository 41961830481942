.ant-tree {
  .ant-tree-node-content-wrapper {
    &.ant-tree-node-selected {
      background-color: #dedada;
    }
  }
}

.ant-select-selection-item {
  &::after {
    display: none !important;
  }
}