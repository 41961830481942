.brands-table {
  .brand-logo {
    cursor: pointer;
  }
}
.url-path {
  display: flex;
  align-items: center;

  .ant-form-item {
    width: 100%;
  }

  .copy-button {
    margin-top: 8px;
    margin-left: 10px;
  }
}