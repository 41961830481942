.dashboard-blk {
    .ant-card {
        height: 100%;
        &.chart-stats {
            margin-bottom: 0;
        }
        .ant-card-body {
            padding: 15px;
            height: 100%;
        }
    }
    @media (max-width: 1300px) {
        .db-col {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}
// Top Orders
.top-orders {
    &.ant-card {
        .ant-card-body {
            padding-bottom: 0;
        }
    }
    .member-info__name {
        color: #000;
        font-size: 14px;
        font-weight: 700;
    }

    .user-purchase__total-amount {
        color: #bd0000;
        font-size: 14px;
        font-weight: 700;
    }
    .member-info-link {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
// Top Reviews & Comments
.top-product-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .product-name {
        font-weight: 700;
        color: #000;
    }
    .product-link {
        text-decoration: none;
        &:hover {
            .product-name {
                text-decoration: underline;
            }
        }
        .product-sku {
            text-decoration: underline;
        }
    }
    .product-image {
        width: 50px;
        height: 50px;
        margin: 0 auto;
        overflow: hidden;
    }
    .ant-pagination-options {
        display: none;
    }
}
.orders-amout-blk {
    .dashboard-header {
        border-bottom: 1px solid #eee;
        padding-bottom: 15px;
    }
    .sl-order-type {
        width: 120px;
    }
    .orders-amout-content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 15px;
        .title {
            font-size: 16px;
            margin-bottom: 5px;
            margin-top: 0;
        }
        .txt {
            font-size: 24px;
            .sml {
                font-size: 16px;
            }
        }
        .price {
            color: #bd0000;
            font-weight: 700;
        }
    }
}
.orders-amout-blk {
    .card-header {
        margin-bottom: 0 !important;
    }
    .dashboard-header {
        .row {
            align-items: center;
        }
    }
    .order-item {
        min-width: 180px;
        min-height: 180px;
        display: flex;
        align-items: center;
        .icon {
            width: 44px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            margin-bottom: 15px;
            &.cart {
                background-color: #e3f7fc;
                color: #1280f5;
            }
            &.dollar {
                background-color: #f9ebeb;
                color: #bd0000;
            }
            i {
                font-size: 28px;
            }
        }
    }
}
.chart-tooltip {
    color: #000;
    .lbl {
        font-weight: 700;
        margin-right: 5px;
    }
    &.revenue {
        .lbl {
            color: #bd0000;
        }
    }
    &.orders {
        .lbl {
            color: #1280f5;
        }
    }
}
.filter-stats {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}