.feedback-page {
  .feedback-page__tabs {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    .ant-btn {
      width: 120px;
      height: 40px;
    }
  }

  .feedback-list {
    position: relative;

    .feedback-list__loading {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($color: #ffffff, $alpha: 0.5);
      transition: opacity 0.15s ease-in;
      opacity: 0;

      &--show {
        opacity: 1;
        z-index: 10;
      }
    }
  }
}

.feedback-item {
  display: flex;
  gap: 24px;

  .feedback-item__main-content {
      display: flex;
      flex-direction: column;
      gap: 8px;
      flex: 1;
  }

  .feedback-item__actions {
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px dashed #cecece;
      padding-left: 24px;

      .feedback-item__actions-solved {
          font-size: 14px;
          font-weight: 700;
          height: 36px;
      }
  }

  .feedback-item__from {
      .feedback-item__from-label {
          margin-right: 4px;
          font-weight: 600;
      }
      .feedback-item__from-info {
          font-weight: 600;
      }
  }

  .feedback-item__createdAt {
      .feedback-item__createdAt-label {
          margin-right: 4px;
          font-weight: 600;
      }
      .feedback-item__createdAt-info {
          font-weight: 500;
      }
  }

  .feedback-item__url {
      .feedback-item__url-label {
          margin-right: 4px;
          font-weight: 600;
      }
      .feedback-item__url-link {
          color: #bd0000;
      }
  }

  .feedback-item__images {
      .feedback-image {
          cursor: pointer;
          width: 100px;
          height: 100px;
          border-radius: 20px;
          overflow: hidden;

          > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
          }
      }
  }
}
