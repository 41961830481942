.user-detail-page {
  .generate-pwd {
    display: flex;
    align-items: center;

    .ant-form-item {
      width: 100%;
    }

    .ant-btn {
      margin-top: 10px;
      margin-left: 20px;
    }
  }
}