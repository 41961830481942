.coupons-table-wrapper {
  .coupon-cell__info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    cursor: pointer;
    user-select: none;

    // &:hover {
    //   .coupon-info__title,
    //   .coupon-info__prefix,
    //   .coupon-info__desc {
    //     text-decoration: underline;
    //   }
    // }

    .coupon-info__title,
    .coupon-info__prefix {
      font-weight: 700;
    }

    .coupon-info__desc {
      color: #808080;
    }
  }

  .coupon-cell__total {
    .coupon-available {
      color: #bd0000;
      font-weight: 700;
      margin-bottom: 10px;
    }
    .coupon-total {
      color: #808080;
    }
  }

  .coupon-cell__discount {
    .coupon-discount {
      font-weight: bold;
      margin-bottom: 10px;
    }
    .coupon-discount-auto {
      color: #808080;
    }
  }

  .coupon-cell__date {
    .coupon__start-date {
      margin-bottom: 10px;
    }
    .coupon__start-date,
    .coupon__end-date {
      font-weight: 700;
    }
  }

  .coupon-status {
    &--active {
      color: #319f50;
    }

    &--draft {
      color: #308add;
    }

    &--expired {
      color: #db0002;
    }

    &--close {
      color: #494949;
    }
  }

  tr td.ant-table-selection-column {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
