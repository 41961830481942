// Loading
.mn-loading {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #ffffff, $alpha: 0.5);
    transition: opacity 0.15s ease-in;
    opacity: 0;

    &.show {
        opacity: 1;
        z-index: 10;
    }
}