.block-wrapper {
  position: relative;
  padding: 5px 0;

  .delete-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    cursor: pointer;
    z-index: 2;
    font-size: 18px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .logo-image {
    width: 100px;
    margin: auto;
  }
}

.sub-menu__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0px;
}
