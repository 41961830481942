.attr-group-wrapper {
  margin: 10px 0;

  .attr-name {
    cursor: pointer;
    text-decoration: underline;
  }

  .add-attr-group-button {
    margin: 15px;
  }

  .product-attr-wrapper {
    margin-bottom: 20px;
  }

  .attr-code {
    font-size: 13px;
  }

  .attr-is-featured {
    font-size: 13px;
    display: block;
    color: #fd8000;
  }

  .attr-is-filterable {
    font-size: 13px;
    display: block;
    color: #57beff;
  }
}