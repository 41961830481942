@import '../../../../../assets/sass/colors';

.selected-stores{
    .ttl {
        font-size: 16px;
        line-height: 22px;;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
}
.product-experience {
    .loading-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background-color: $color-white-opacity-60;
        margin: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}