@import '../variables';

$_bg: (default: rgba(#4a505c, 0.08),
  hover: rgba(#4a505c, 0.08),
  focus: #fff,
  disabled: rgba(#ccc, 0.1));

$_border: (default: rgba(#4a505c, 0.08),
  hover: rgba(#4a505c, 0.08),
  focus: rgba(#4a505c, 0.08),
  disabled: rgba(#ccc, 0.5));

$_color: (default: #4a505c,
  hover: #4a505c,
  focus: #4a505c,
  disabled: #bdbdbd);

$_module-rem: $module-rem;
$_placeholder-color: #4a505c;
$_border-style: solid;
$_border-width: 1px;
$_fs: 1rem;
$_lh: $_module-rem * 2 / $_fs;
$_padding-h: $_module-rem * 2;
$_padding-v: $_module-rem;
$_shape: 5px;
$_animation: $animation;

.ant-input,
.ant-picker {
  box-shadow: none;
  border-color: map_get($_border, default);
  border-radius: $_shape;
  height: 48px;
  color: map_get($_color, default);
  width: 100%;

  @media #{$max1199} {
    max-width: 100%;
  }

  &:hover {
    background-color: map-get($_bg, hover) !important;
    border-color: map-get($_border, hover) !important;
    outline: none !important;
  }

  &:disabled {
    background-color: map-get($_bg, disabled) !important;
    border-color: map-get($_border, disabled) !important;
  }

  &:focus {
    outline: none !important;
  }
}

.ant-calendar-picker,
.ant-calendar-picker-input {
  max-width: 100%;
  outline: none;

  :not(.ant-input-disabled),
  &:focus {
    border: none;
    box-shadow: none;
    outline: none !important;
  }
}

.ant-calendar-picker-clear {
  align-items: center;
  display: flex;
  color: rgba(#4a505c, 0.5);
  background: #fff;

  svg {
    height: 16px;
    width: 16px;
  }
}