//
// Basic Bootstrap table
//

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: $spacer;

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  .table {
    background-color: $body-bg;
  }
}


//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-sm-cell-padding;
  }
}


// Bordered version
//
// Add borders all around the table and between all the columns.

.table-bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $table-border-width);
    }
  }
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
  tbody tr:nth-of-type(odd) {
    background-color: $table-bg-accent;
  }
}


// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
  tbody tr {
    @include hover {
      background-color: $table-bg-hover;
    }
  }
}


// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

// Generate the contextual variants
@include table-row-variant(active, $table-bg-active);
@include table-row-variant(success, $state-success-bg);
@include table-row-variant(info, $state-info-bg);
@include table-row-variant(warning, $state-warning-bg);
@include table-row-variant(danger, $state-danger-bg);


// Inverse styles
//
// Same table markup, but inverted color scheme: dark background and light text.

.thead-inverse {
  th {
    color: $table-inverse-color;
    background-color: $table-inverse-bg;
  }
}

.thead-default {
  th {
    color: $table-head-color;
    background-color: $table-head-bg;
  }
}

.table-inverse {
  color: $table-inverse-color;
  background-color: $table-inverse-bg;

  th,
  td,
  thead th {
    border-color: $body-bg;
  }

  &.table-bordered {
    border: 0;
  }
}



// Responsive tables
//
// Add `.table-responsive` to `.table`s and we'll make them mobile friendly by
// enabling horizontal scrolling. Only applies <768px. Everything above that
// will display normally.

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar; // See https://github.com/twbs/bootstrap/pull/10057

  // Prevent double border-color on horizontal scroll due to use of `display: block;`
  &.table-bordered {
    border: 0;
  }
}
