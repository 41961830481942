.coupon-detail {
  .coupon-btn-group {
    position: absolute !important;
    top: 40px;
    left: 2.14286rem;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .detail-toolbar__close-btn,
  .detail-toolbar__public-btn,
  .detail-toolbar__draft-btn,
  .detail-toolbar__download-btn {
    &.show-loading {
      .ant-btn-loading-icon {
        display: inline-block;
      }
    }
    .ant-btn-loading-icon {
      display: none;
    }
  }
  .detail-toolbar__close-btn {
    position: absolute !important;
    top: 40px;
    right: 140px;
    z-index: 1;
  }
  .detail-toolbar__public-btn {
    position: absolute !important;
    top: 40px;
    right: 140px;
    z-index: 1;
  }
  .detail-toolbar__public-btn,
  .detail-toolbar__download-btn {
    background-color: #319f50;
    color: #ffffff;
    border-color: #319f50;

    &:hover,
    &:focus {
      border-color: #319f50;
      color: #ffffff;
      background-color: #319f50;
    }
  }

  .detail-toolbar__draft-btn {
    position: absolute !important;
    top: 40px;
    right: 140px;
    z-index: 1;
    background-color: #308add;
    color: #ffffff;
    border-color: #308add;

    &:hover,
    &:focus {
      border-color: #308add;
      color: #ffffff;
      background-color: #308add;
    }
  }

  .detail-toolbar__close-btn {
    position: absolute !important;
    top: 40px;
    right: 140px;
    z-index: 1;
    background-color: #000;
    color: #ffffff;
    border-color: #000;

    &:hover,
    &:focus {
      border-color: #000;
      color: #ffffff;
      background-color: #000;
    }
  }

  .coupon-tracking-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: #000;
  }

  .coupon-general-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .coupon-title-wrapper {
      font-size: 16px;
    }

    .coupon-title,
    .coupon-discount {
      font-weight: 600;
    }

    .coupon-title {
      margin-right: 4px;
    }

    .coupon-discount {
      color: #319f50;
    }

    .coupon-date-range {
      display: flex;
      align-items: center;
      gap: 24px;
      font-size: 14px;
    }

    .coupon-date-display {
      font-weight: 700;
    }
  }

  .coupon-status {
    &--active {
      color: #319f50;
    }

    &--draft {
      color: #308add;
    }

    &--expired {
      color: #db0002;
    }

    &--close {
      color: #494949;
    }
  }

  .coupon-statistic {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .coupon-used {
      color: #319f50;
    }

    .coupon-total {
      font-weight: 700;
      margin-right: 8px;
    }

    .coupon-view-order-history {
      color: #808080;
      font-weight: 700;
      text-decoration: underline;
      cursor: pointer;
      user-select: none;
      margin-right: 8px;
    }

    .view-coupons {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;
      background: #eee;
      padding: 4px 16px;
      border-radius: 10px;
      cursor: pointer;

      > img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
        margin-top: -3px;
      }
    }

    .coupon-remaining-available-days {
      color: #808080;
    }
  }
}
