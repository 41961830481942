.collection-detail {
  .slug-col {
    display: flex;
    align-items: center;

    .ant-form-item {
      width: 100%;
    }

    .copy-button {
      margin-top: 12px;
      margin-left: 10px;
    }
  }
  .collection__products-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    .products-header__title {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      letter-spacing: 0.2em;
      color: #1b1b1b;

      > span {
        color: #808080;
        font-size: 12px;
        line-height: 14px;
      }

      > .filter-btn {
        cursor: pointer;
      }
    }

    .products-header__toolbar {
      display: flex;
      align-items: center;
      gap: 24px;
    }

    .products-header__toolbar-sort {
      display: flex;
      align-items: center;
      gap: 12px;

      .toolbar-sort__option,
      .toolbar-sort__separate {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        user-select: none;
      }

      .toolbar-sort__option {
        cursor: pointer;

        &--active {
          color: #bd0000;
        }

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .products-header__add-btn {
      background: #1b1b1b;
      border-radius: 5px;
      padding: 8px 16px;
      cursor: pointer;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: white;
      transition: all 0.2s ease;
      text-transform: uppercase;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .collections-product__remove {
    height: 32px;
  }

  .products-header__filter {
    border: 1px solid #d7d7d7;
    padding: 12px;
    border-radius: 6px;
  }
}

.collection-detail__loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.seo-modal__content {
  position: relative;
}

.add-product-modal {
  .custom-modal {
    height: 85%;
    > .ant-row {
      height: auto !important;
    }
  }
  .content-wrapper {
    height: auto;
  }
  .products-table-wrapper {
    margin: 0px;
  }
  .add-product-modal__content {
    margin-top: 6px;

    .form-item__search {
      .ant-form-item-label {
        visibility: hidden;
      }
      .search-btn {
        width: 100%;
      }
    }
  }
  .ant-table-empty .ant-table-body {
    overflow: hidden !important;
  }
  .selected-products-label {
    margin-bottom: 8px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .pagination-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 16px;
  }
}
