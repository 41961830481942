// Toggles
//
// Used in conjunction with global variables to enable certain theme features.

@mixin box-shadow($shadow...) {
  @if $enable-shadows {
    box-shadow: $shadow;
  }
}

@mixin transition($transition...) {
  @if $enable-transitions {
    @if length($transition) == 0 {
      transition: $transition-base;
    } @else {
      transition: $transition;
    }
  }
}

// Utilities
@import "./mixins/breakpoints";
@import "./mixins/hover";
@import "./mixins/image";
@import "./mixins/badge";
@import "./mixins/resize";
@import "./mixins/screen-reader";
@import "./mixins/size";
@import "./mixins/reset-text";
@import "./mixins/text-emphasis";
@import "./mixins/text-hide";
@import "./mixins/text-truncate";
@import "./mixins/transforms";
@import "./mixins/visibility";

// // Components
@import "./mixins/alert";
@import "./mixins/buttons";
@import "./mixins/cards";
@import "./mixins/pagination";
@import "./mixins/lists";
@import "./mixins/list-group";
@import "./mixins/nav-divider";
@import "./mixins/forms";
@import "./mixins/table-row";

// // Skins
@import "./mixins/background-variant";
@import "./mixins/border-radius";
@import "./mixins/gradients";

// // Layout
@import "./mixins/clearfix";
// @import "./mixins/Navbar-align";
@import "./mixins/grid-framework";
@import "./mixins/grid";
@import "./mixins/float";
