.gallery-page {
  position: relative;

  .loading-screen {
    .bg-white {
      background: #fff;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-card-body {
    padding: 10px 15px !important;
  }

  .ant-card-cover {
    height: 75%;
  }

  .preview-img {
    object-fit: contain;
  }

  .asset-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;

    .title {
      cursor: pointer;
      white-space: nowrap;
      max-width: 110px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-extension {
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .image-info {
    font-size: 0.85em;
    text-transform: uppercase;
  }

  .custom-row {
    display: flex;
    flex-wrap: wrap;

    .item-col {
      padding: 10px;
      min-width: 20%;
      flex-basis: 20%;
      position: relative;

      .delete-btn {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 10px;
        z-index: 99;
        font-size: 28px;
        width: 32px;
        height: 32px;
        padding: 0;
        background: #00000040;
        border: none;
        color: #fff;

        &:hover {
          background: #00000090;
        }
      }
    }

    .item-full-col {
      width: 100%;
      flex-basis: 100%;
    }
  }
}