@import 'assets/sass/variables';

.user-list-page {
  .user-list-table {
    .lock-button {
      &:hover {
        border-color: $success-color;
      }
    }
  }
}