/**
 * CSS GROUPSIZE MENU
 */
.tabs-card {
    position: sticky;
    top: 0;
    .lst {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        padding: 5px 0;
        cursor: pointer;
        a {
          text-decoration: none;
          &:hover {
            text-decoration: underline;
            color: #000;
          }
          &.active {
            font-weight: bold;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
}