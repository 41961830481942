.article-detail-page {
  .ql-container {
    .ql-editor {
      min-height: 60vw;
      max-height: 60vw;
    }
  }
}

.site-tag-plus {
  background: #fff;
  border-style: dashed;
}

.edit-tag {
  user-select: none !important;
}

.tag-input {
  width: 78px !important;
  margin-right: 8px !important;
  vertical-align: top;
}

[data-theme="dark"] .site-tag-plus {
  background: transparent;
  border-style: dashed;
}