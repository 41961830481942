.form-signin {
  .ant-form-item-has-error {
    .ant-input-affix-wrapper {
      border-color: #ed253d !important;

      &.ant-input-affix-wrapper-focused {
        border-color: #ed253d !important;
        box-shadow: unset;
      }
    }
  }

  .ant-input-affix-wrapper {
    border-color: #d9d9d9 !important;

    &.ant-input-affix-wrapper-focused {
      border-color: #d9d9d9 !important;
      box-shadow: unset;
    }
  }

  .ant-input {
    background-color: #fff;

    &:hover {
      background-color: #fff !important;
    }

    &:focus {
      background-color: #fff !important;
    }
    &[type="number"] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button  {
        -webkit-appearance: none;
        margin: 0
      }
      -moz-appearance:textfield; 
    }
  }
}