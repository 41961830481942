.promotion-table {
    .promotion-name {
        cursor: pointer;
    }
}
.btn-close-promotion {
    position: absolute !important;
    top: 40px;
    right: 140px;
    z-index: 1;
    &.r-15 {
        right: 15px;
    }
}
.ant-picker-ok {
    .ant-btn {
        background-color: #000 !important;
        color: #fff !important;
        border-color: #000 !important;
        padding: 5px 10px !important;
        &::after {
            content: "Xác nhận";
            font-size: 14px;
        }
        span {
            display: none;
        }
    }
}