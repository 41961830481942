.coupon-order-detail-modal {
  .order-detail__info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .order-detail__info-item {
      display: flex;
      align-items: center;
      gap: 40px;
    }

    .info-item__label,
    .info-item__value {
      min-width: 120px;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      color: #777777;
    }

    .info-item__value {
      color: #1b1b1b;
    }

    .info-item__value--bold {
      font-weight: 700;
    }
  }

  .order-detail__status {
    margin: 24px 0px;
    padding: 10px 0px;
    display: flex;
    align-items: center;
    gap: 16px;

    .status-label,
    .status-value {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
    }

    .status-value {
      &--processing {
        color: #159981;
      }
      &--shipping {
        color: #ff6600;
      }
      &--cancelled {
        color: #bd0000;
      }
    }
  }

  .order-detail__products {
    border-top: 1px solid #808080;
    border-bottom: 1px solid #808080;
    margin-bottom: 24px;

    .product-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 16px 0px;
      border-bottom: 1px dashed #808080;

      &:last-child {
        border-bottom: none;
      }
    }

    .product-item__main-content {
      display: flex;
      align-items: center;
      gap: 20px;
      flex: 1;

      > img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }
    }

    .product-item__info {
      .product-item__name {
        color: #1b1b1b;
        font-size: 13px;
        font-weight: 400;
        line-height: 18px;
      }
      .product-item__color,
      .product-item__coupon {
        color: #777;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }

      .product-item__coupon {
        color: #1b1b1b;

        > span {
          font-weight: 700;
          color: #bd0000;
        }
      }
    }

    .product-item__price {
      .product-item__sale-price {
        text-align: right;
        color: #777;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 8px;
      }

      .product-item__final-sale-price {
        color: #000;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-align: right;
      }
    }
  }

  .order-detail__total-price {
    color: #777;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: right;

    > span {
      color: #1b1b1b;
    }
  }
}
