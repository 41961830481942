.payment-order-block {
  .toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 48px;
  }

  .bank-block {
    position: relative;

    .loading-wrapper {
      background-color: rgba($color: #fff, $alpha: 0.8);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px;
      margin: 0px;
      z-index: 999;
    }
  }
}
