.products-page {
  position: relative;
  .blk-saleonline {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }
  .product-table {
    .product-image {
      width: 60px;
      height: 60px;
      cursor: pointer;

      img {
        object-fit: cover;
      }
    }

    .product-name {
      text-decoration: underline;
      cursor: pointer;
      min-width: 250px;
    }

    .deleted-product-name {
      white-space: nowrap;
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .product-price {
      color: #db0000;
    }

    .old-price {
      text-decoration: line-through;
    }

    .product-type {
      text-transform: capitalize;
    }
  }
}