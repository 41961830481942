@import '../variables';

$_shape: 2px;

.ant-list {
  .ant-list-item {
    border: none;
    padding: ($module-rem * .8) 0;
  }
  .ant-list-item-meta-description {
    font-size: 13px;
  }
  .description-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .description {
      margin-right: $module-rem;
    }
    .time {
      font-size: 0.9em;
      opacity: 0.8;
    }
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .list-img {
    border-radius: $_shape;
  }
}

.ant-list-item-meta-title {
  margin-top: 0;
}
