.box-attributes-colors {
    .ant-form-item {
        flex-direction: column;
    }
    .ant-form-item-label {
        text-align: left;
    }
    .sml-text {
        font-size: 12px;
        color: #ed253d;
        display: none;
        margin-top: 5px;
        &.shw {
            display: block;
        }
    }
    .ipt-color {
        &.err {
            .ant-select-selector {
                border-color: #ed253d !important;
            }
        }
    }
    .ant-upload.ant-upload-drag {
        .ant-upload {
            padding: 5px 0;
        }
        p.ant-upload-text {
            font-size: 14px;
        }
        p.ant-upload-drag-icon {
            margin-bottom: 5px;
            .anticon {
                font-size: 34px;
            }
        }
    }
    .btn-color-del {
        margin-top: 37px;
    }
    .blk-color-ttl {
        font-size: 20px;
        line-height: 24px;
        margin-top: 0;
        margin-bottom: 10px;
    }
    .attr-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 20px;
        position: relative;
        padding-left: 10px;
        &::before {
            content: '';
            width: 6px;
            height: 6px;
            background-color: #000;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
    }
    .box-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .chk-item {
            margin-right: 20px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    input[type="checkbox"] {
        display: none;
        + label {
            cursor: pointer;
            display: flex;
            align-items: center;
            .mask {
                border: 1px solid rgba(74,80,92,.1);
                background-color: #fff;
                border-radius: 3px;
                height: 20px;
                width: 20px;
                margin-right: 10px;
                position: relative;
                &::after {
                    position: absolute;
                    display: none;
                    border: 2px solid #fff;
                    border-top: 0;
                    border-left: 0;
                    transform: rotate(45deg) scale(1) translate(-50%,-50%);
                    opacity: 1;
                    transition: all .2s cubic-bezier(.12,.4,.29,1.46) .1s;
                    content: " ";
                    width: 7px;
                    height: 11px;
                    top: 50%;
                    left: 25%;                
                }
            }
        }
        &:checked {
            + label {
                .mask {
                    background: rgba(124,219,134,.2);
                    border-color: rgba(124,219,134,.2);
                    &::after {
                        border-color: #7cdb86;
                        display: table;
                    }
                }
            }
        }
        &:disabled {
            + label {
                cursor: not-allowed;
                pointer-events: none;
                .mask {
                    background-color: rgba(0,0,0,.08);
                    border-color: #d9d9d9;
                }
            }
        }
    }
    .blk-color-item {
        border-bottom: 1px solid #cfcfcf;
        &:last-child {
            border-bottom: none;
        }
    }
}