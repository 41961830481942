.select-products-section {
  margin-top: 32px;

  .searched-product-wrap {
    padding: 16px 16px 16px 0px;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-self: center;
  }

  .selected-product-col {
    border: 1px dashed #dddddd;
  }
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
  padding-left: 48px;
  padding-right: 48px;
}

.relative {
  position: relative;
}

.loading-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999;

  &::before {
    content: '';
    background: #ffffff;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
  }
}