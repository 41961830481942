@import "../variables";

$_bg: (
        default: rgba(#4a505c, 0.08),
        hover: rgba(#4a505c, 0.08),
        focus: #fff,
        disabled: rgba(#ccc, 0.1)
);

$_border: (
        default: rgba(#4a505c, 0.08),
        hover: rgba(#4a505c, 0.08),
        focus: rgba(#4a505c, 0.08),
        disabled: rgba(#ccc, 0.5)
);

$_color: (
        default: #4a505c,
        hover: #4a505c,
        focus: #4a505c,
        disabled: #bdbdbd
);

$_module-rem: $module-rem;
$_placeholder-color: #4a505c;
$_border-style: solid;
$_border-width: 1px;
$_fs: 1rem;
$_lh: $_module-rem * 2 / $_fs;
$_padding-h: $_module-rem * 2;
$_padding-v: $_module-rem;
$_shape: 20px;
$_animation: $animation;


.ant-time-picker,
.ant-time-picker-input {
  max-width: 100%;
  outline: none;

  :not(.ant-input-disabled),
  &:focus {
    border: none;
    box-shadow: none;
    outline: none !important;
  }
}

.ant-time-picker-clear {
  align-items: center;
  display: flex;
  color: rgba(#4a505c, .5);
  background: #fff;

  svg {
    height: 16px;
    width: 16px;
  }
}
