@import '../../../assets/sass/variables';

@keyframes loaderAnimation{
  0%{
    background-position: -468px 0
  }
  100%{
    background-position: 468px 0
  }
}
.footer {
  background: #FBFBFB;
  border-top: 1px solid #E9E9E9;
  display: block;
  height: $footer-height;

  .footer-wrap {
    background: inherit;
    height: 100%;
    margin: 0 auto;
    padding: ($module-rem / 2) ($module-rem * 2);
    position: relative;

    .row {
      height: 100%;
    }
    .info {
      font-size: .8rem;
      opacity: .8;
    }
    .footer-skeleton {
      background: inherit;
      height: 100%;
      left: 0;
      padding: $module-rem ($module-rem * 2);
      position: absolute;
      right: 0;
      top: 0;
      transition:
              .2s opacity 0s $animation,
              0s visibility 0s $animation;
      z-index: 990;

      .row {
        height: inherit;
      }
      .bg {
        background: #ebebeb;
      }
      .bg-1 {
        background: #f5f5f5;
      }
      .animated-bg {
        overflow: hidden;
        position: relative;
      }
      .animated-bg:before {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loaderAnimation;
        animation-timing-function: linear;
        background: transparent;
        background: linear-gradient(to right, transparent 10%, rgba(#fff,.5) 18%, transparent 33%);
        background-size: 800px 100%;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
      .page-breadcrumbs {
        margin: 0 -($module-rem / 2);

        .item {
          border-radius: 3px;
          display: block;
          height: ($module-rem * 1.5);
          margin: 0 ($module-rem / 2);
          width: 230px;
        }
      }
      .info {
        align-items: center;
        display: flex;
        margin: 0 -($module-rem / 2);

        .version {
          border-radius: 3px;
          display: block;
          height: $module-rem;
          margin: 0 ($module-rem / 2);
          width: 80px;
        }
        .settings {
          background: accent-palette(500);
          border-radius: 500px;
          display: block;
          height: $module-rem * 2;
          margin: 0 ($module-rem / 2);
          width: $module-rem * 2;
        }
      }
    }
  }
  &.boxed {
    .footer-wrap {
      max-width: $boxed-width;
      max-width: var(--boxed-width);
    }
  }
  &.loaded {
    .footer-wrap {
      .footer-skeleton {
        opacity: 0;
        transition:
                .2s opacity .2s $animation,
                0s visibility .4s $animation;
        visibility: hidden;
      }
    }
  }
}
