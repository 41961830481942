#root {
  height: 100%;

  .App {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
  }
}

// Custom modal styles
.coupon-order-detail-modal {
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-header {
    padding: 24px;
    border-radius: 16px 16px 0 0;
  }
  .ant-modal-title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
  }
}

.base-modal {
  .ant-modal-content {
    border-radius: 16px;
  }
  .ant-modal-header {
    padding: 24px;
    border-radius: 16px 16px 0 0;
  }
  .ant-modal-title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
  }
}

.coupon-list-modal {
  top: 20px !important;
  .ant-modal-title {
    color: #000;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
  }
}
