@import '../../../assets/sass/colors.scss';

.members-page {
  .member-info {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__name {
      color: #000;
      font-size: 14px;
      font-weight: 700;
    }

    &__email,
    &__dob {
      color: #808080;
      font-size: 14px;
      font-weight: 400;
    }

    &__phone {
      color: #1b1b1b;
      font-size: 14px;
      font-weight: 700;
    }

    &:hover {
      cursor: pointer;

      .member-info__name {
        text-decoration: underline;
      }
    }
  }

  .user-purchase {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__total-amount {
      color: #bd0000;
      font-size: 14px;
      font-weight: 700;
    }
    &__total-orders {
      color: #808080;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .user-discount-code {
    &__total {
      color: #1b1b1b;
      font-size: 14px;
      font-weight: 700;
    }
    &__available-txt {
      color: #808080;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .user-behavior {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__reviews,
    &__comments {
      color: #006be3;
      font-size: 14px;
      font-weight: 700;
      text-decoration-line: underline;
    }
  }

  .user-state {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &__status {
      font-size: 14px;
      font-weight: 400;
      &--active {
        color: #159981;
      }
      &--initial {
        color: rgb(234, 168, 0);
      }
      &--inactive {
        color: #bd0000;
      }
    }

    &__rank {
      display: flex;
      padding: 8px 16px;
      align-items: center;
      width: fit-content;
      border-radius: 5px;
      border: 1px solid #78d4a1;
      background: #eafbe2;

      > span {
        color: #007c66;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }

  .user-actions {
    display: flex;
    justify-content: flex-end;
    .user-actions__block {
      width: fit-content;
      padding: 4px;
      border: 1px solid #b6b6b6;
      border-radius: 5px;
      cursor: pointer;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}

.total-members {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $color-black;
  display: flex;
  align-items: center;
  gap: 15px;
  .total {
    span {
      color: $color-red;
      font-size: 24px;
    }
  }
}
