.coupon-present-page {
  .block-item {
    font-size: 24px;
  }

  .seo {
    height: 100px;
  }

  .main-slider-block {
    height: 300px;
  }
}
