.system-color-page {
  .color-image {
    width: 64px;
    height: 64px;
    cursor: pointer;
  }

  .color-name {
    cursor: pointer;
    text-decoration: underline;
  }
}
