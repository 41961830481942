.product-list-template {
  .featured-product-wrapper {
    margin-bottom: 24px;
    .featured-product-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2em;
      color: #1b1b1b;
    }

    .featured-product-note {
      font-style: italic;
      margin-bottom: 8px;
    }
  }
  .product-list-template__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .toolbar-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.2em;
      color: #1b1b1b;
    }
  }
}
