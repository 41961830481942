.rider-home-present-page {
  background: #fff;
  padding: 10px;

  .header-home {
    display: flex;

    .headerLogo {
      width: 210px;
      height: 52px;
    }

    .headerSearch {
      width: 500px;
    }
  }

  .main-content {
    .hero-banner {
      height: 330px;
      font-size: 32px;
    }

    .homeSpotlightBadges {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }

    .homeBannerMid {
      height: 100px;
      font-size: 32px;
    }

    .homeShockPrice,
    .rider-blog {
      display: block !important;

      .shock-price-title,
      .rider-blog-title {
        margin-bottom: 10px;
      }

      .shock-price,
      .rider-blog-contents {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
      }
    }

    .categoriesIconMenu {
      display: block !important;

      .category-title {
        margin-bottom: 10px;
      }

      .category {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        .badge {
          margin: 30px;
        }
      }
    }

    .productTabs {
      height: 300px;
      font-size: 32px;
    }

    .new-arrival-accessories,
    .gallery-block {
      height: 300px;
      font-size: 32px;
    }

    .new-arrival-accessories,
    .rider-blog {
      cursor: not-allowed;
    }

    .featured-categories {
      display: flex;
      flex-direction: column;
      padding: 16px;
      height: 400px;

      .featured-category {
        flex: 1;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #d9d9d9;
        background: #fff;
        font-size: 30px;
      }
    }

    .homeVideos {
      display: block !important;
      padding: 10px 7px;

      .main-video {
        height: 395px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed #d9d9d9;
        background: #fff;
        font-size: 30px;
      }
    }

    .homeNews {
      display: block !important;
      padding: 10px;

      .home-news-title {
        text-align: left;
        margin-bottom: 10px;
      }
    }

    .footerMenus {
      height: 260px;
    }

    .footer {
      height: 350px;
      background: #101010;
      color: #fff;
      flex-direction: column;
      position: relative;

      .header-home {
        position: absolute;
        background: #000000;
        bottom: 0;
        width: 100%;
        padding: 0 40px;

        .footerLogo {
          color: #c1c1c1;
          background: #3f3f3f;
        }
      }
    }
  }

  .badge {
    margin: 0 20px;

    .badge-square {
      width: 60px;
      height: 60px;
      border-radius: 8px;
      background: #d3d3d3;
      margin: 5px 0px;
    }

    .badge-circle {
      width: 50px;
      height: 50px;
      background: #d3d3d3;
      margin: auto;
      border-radius: 50%;
    }

    .badge-line {
      width: 60px;
      height: 10px;
      margin: 5px 0px;
      background: #d3d3d3;
    }
  }

  .product-item {
    margin: 0 10px;

    .product-image {
      width: 180px;
      height: 180px;
      background: #f5f5f5;
      border: 1px dashed #cccccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .product-line-1 {
      width: 180px;
      height: 25px;
      margin: 10px 0px;
      background: #d3d3d3;
    }

    .product-line-2 {
      width: 180px;
      height: 20px;
      margin: 10px 0px;
      background: #d3d3d3;
      border-radius: 10px;
    }
  }

  .news-item {
    display: flex;
    margin: 10px 0;

    .news-image {
      width: 80px;
      height: 80px;
      background: #f5f5f5;
      border: 1px solid #cccccc;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .news-line {
      margin-left: 10px;

      .line {
        width: 120px;
        height: 12px;
        background: #d3d3d3;
        margin-bottom: 5px;
      }
    }
  }
}
.rider-home-present-page {
  .block-item {
    &.seo {
      height: 100px;
    }
  }
}

.rider-home__temp-popup {
  .ant-form {
    height: 100% !important;
  }
}
