.layout-list {
  .layout-list__toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .layout-list-pagination {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
  }
}
.filter-toolbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
