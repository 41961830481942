.product-detail-page {
  .main-col {
    &.ant-col-16 {
      flex: 1;
      max-width: 100%;
    }
  }

  .right-col {
    &.ant-col-8 {
      max-width: 380px;
    }
  }

  .shipping-info-card {
    box-shadow: unset;
    border: 1px solid rgba(74, 80, 92, 0.2);
  }

  .url-path {
    display: flex;
    align-items: center;

    .ant-form-item {
      width: 100%;
    }

    .copy-button {
      margin-top: 8px;
      margin-left: 10px;
    }
  }

  .small-editor {
    .ql-container {
      .ql-editor {
        max-height: 400px;
      }
    }
  }

  .loading-wrapper {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
  }
}

.product-name-overflow {
  min-width: 300px;
}

.existed-product-name-overflow {
  max-width: 200px;
}

.read-only-field {
  height: 48px;
  display: flex;
  align-items: center;
}

.color-picker {
  height: 48px;
}
.variant-color-upload {
  .ant-upload.ant-upload-drag {
    background: none;
    border: none;
    .ant-upload-btn {
      padding: 0;
    }
    .ant-upload-drag-icon {
      display: none;
    }
    .ant-upload-text {
      color: #820000;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}
.variant-color-blk {
  &.blocked {
    .preview-image-wrapper {
      pointer-events: none;
      .delete-icon {
        display: none;
      }
    }
  }
  .variant-color-upload,
  .ant-input,
  .color-img,
  .variant-size {
    flex: 0 0 auto;
  }
  .color-img {
    border: 1px solid rgba(74, 80, 92, 0.08);
  }
  .ant-input {
    max-width: 300px;
  }
  .preview-image-wrapper {
    max-width: 50px;
    min-height: 50px;
  }
  .preview-image {
    max-width: 50px !important;
  }
  .preview-img {
    width: 100%;
  }
  .variant-size {
    font-weight: bold;
    color: #000;
  }
}
.blk-variants-prods {
  .ant-table-body {
    max-height: 800px !important;
  }
}
.loading-icon {
  color: #820000;
}
