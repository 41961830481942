@import '../variables';

$_shape: 20px;
$_fs: 1rem;

$_fs-sm: ($_fs * 0.9);
$_lh-sm: $module-rem * 2 / $_fs-sm;
$_padding-v-sm: ($module-rem / 2);
$_padding-h: $module-rem / 2;

// size lg
$_fs-lg: ($_fs * 1.3);
$_lh-lg: $module-rem * 2 / $_fs-lg;
$_padding-v-lg: $module-rem * 1.5;

$_bg: (
  default: rgba(#4a505c, 0.08),
  hover: rgba(#4a505c, 0.08),
  focus: #fff,
  disabled: rgba(#ccc, 0.1),
);

$_border: (
  default: rgba(#4a505c, 0.08),
  hover: rgba(#4a505c, 0.08),
  focus: rgba(#4a505c, 0.08),
  disabled: rgba(#ccc, 0.5),
);

$_color: (
  default: #4a505c,
  hover: #4a505c,
  focus: #4a505c,
  disabled: #bdbdbd,
);
.ant-select-show-search .ant-select-auto-complete {
  position: relative;
  padding: 0;

  .ant-select-selection {
    padding: 0;
  }
  .ant-select-search {
    border-radius: $_shape;
    border: none;
    height: 100%;
  }
}

.ant-select-auto-complete.ant-select .ant-select-selection--single {
  padding: 0;
  background: transparent;

  .ant-input {
    box-shadow: none;
    background: map_get($_bg, default);
    color: map_get($_color, default);
    height: 100%;
  }
}

.ant-select-auto-complete.ant-select .ant-select-selection__placeholder {
  margin-left: $module-rem * 2.5;
}
