.popup-wrapper {
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.custom-modal {
  padding: 20px;
  background-color: #fff;
  z-index: 999;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 75%;
  height: 80%;
  box-shadow: 1px 2px 20px 8px rgba(#000, 0.2);

  .title-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;

    .close-btn {
      cursor: pointer;
    }
  }

  .save-button-wrapper {
    background-color: #F4F5F8;
    padding: 15px 20px;
    margin-bottom: 5px;

    .btn-delete {
      margin-right: 10px;
    }
  }

  .content-wrapper {
    height: inherit;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 20px;
  }
}