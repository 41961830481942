.event-page-detail {
  .event-page__date-info {
    margin-bottom: 24px;

    .date-info__label {
      margin-right: 4px;
      font-size: 14px;
    }

    .date-info__value {
      font-weight: 700;
    }

    .date-info__divider {
      margin: 0px 12px;
    }
  }

  .block-item {
    min-height: 240px;
    font-size: 24px;

    &--disabled {
      cursor: not-allowed;
    }

    &.block-item__menu {
      min-height: 120px;
    }
  }

  .blockId-form-desc {
    font-size: 12px;
  }
}
