@import '../variables';

.ant-modal {

  .ant-modal-header,
  .ant-modal-footer {
    border: none;
  }

  .ant-modal-body {
    padding-top: 10px;
  }

  .ant-modal-footer {
    padding: $module-rem * 2;

    .modal-footer {
      border-radius: 0 0 $shape $shape;
      margin: -$module-rem * 2;
      padding: $module-rem * 2;
      background-color: rgba(#000, 0.05);
    }
  }
}

.ant-modal-mask {
  background-color: rgba(#000, 0.15);
}