.article-category-page {
  .cate-list-item {
    cursor: pointer;
    padding-left: 5px !important;
    padding-right: 5px !important;
    transition: all ease 0.2s;

    &:hover {
      background: #F4F5F8;
    }

    &.active {
      background: #F4F5F8;
    }
  }
}