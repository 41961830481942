.event-page {
  .create-form-seo__title {
    font-weight: 700;
    margin-bottom: 12px;
  }
  .ant-select-item-option {
    display: flex !important;
  }
  .event-page__title {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
  }
}
