@import '../../assets/sass/colors';

.btn-export {
    display: flex;
    align-items: center;
    background-color: $color-green;
    border-color: $color-green;
    &.ant-btn {
        &:hover, &:focus {
          background-color: $color-white;
          border-color: $color-green;
          span {
            color: $color-green;
          }
        }
    }
    span {
      font-size: 16px;
      color: $color-white;
      &.anticon {
        font-size: 22px;
        min-height: auto;
        height: auto;
      }
    }
  }