.preview-image-wrapper {
  background: #c0c0c040;
  min-height: 100px;

  .preview-image {
    position: relative;
    margin: auto;
    text-align: center;

    .delete-icon {
      cursor: pointer;
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 99;
      font-size: 28px;
      width: 32px;
      height: 32px;
      padding: 0;
      background: #00000040;
      border: none;
      color: #fff;

      &:hover {
        background: #00000090;
      }
    }

    .preview-img {
      object-fit: contain;
    }
  }
}

.ant-upload-list {
  display: none !important;
}