.products-table-wrapper {
  .collection__products {
    .product-name {
      font-weight: 500;
    }
    .product-price {
      font-weight: 500;
    }
    .product--published {
      color: #319f50;
      margin-bottom: 2px;
    }
  }

  tr td.ant-table-selection-column {
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
}
