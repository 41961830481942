@import 'assets/sass/variables';

.logo {
  display: block;
  position: relative;
  padding: $module-rem ($module-rem * 2);
  margin: 0;

  .logo-wrap {
    align-items: center;
    color: inherit;
    display: flex;
    height: 100%;
    text-decoration: none;

    .logo-img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      vertical-align: top;
    }

    .logo-text {
      display: block;
      font-size: 20px;
      line-height: 1;
      margin-left: ($module-rem * 2);
    }
  }
}