.qr-code-wrap {
    max-width: 420px;
    margin-bottom: 30px;
    border: 1px solid rgba(74, 80, 92, 0.08);
    border-radius: 5px;
    padding: 0 15px;
    .ttl {
        font-size: 16px;
        line-height: 20px;
    }   
    .qr-code-img {
        max-width: 150px;
        margin: 0 auto;
        box-shadow: 0 3px 5px rgba(0,0,0,0.1);
    }
    .code {
        color: #000;
        font-size: 24px;
        text-align: center;
    }
    .guide {
        margin-bottom: 10px;
    }
    .download {
        margin-bottom: 20px;
        a {
            font-weight: bold;
            &:hover {
                text-decoration: none;
            }
        }
    }
    .ant-btn.key-btn {
        background-color: #000;
        color: #fff;
        border: none;
        &:hover {
            color: #fff;
            background-color: #2d2c3c;
        }
    }
}