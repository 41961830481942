@import '../../../assets/sass/colors';

.member-details {
  .member-info-toolbar {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    right: 24px;
    top: 24px;
  }

  .member-info__heading {
    color: $color-black;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .member-name {
    color: $color-black;
    font-size: 20px;
    font-weight: 700;
  }

  .member-info__item {
    padding: 16px 0px;
    display: flex;
    align-items: center;

    .member-info__item-label,
    .member-info__item-value {
      color: $color-black;
      font-size: 14px;
      font-weight: 400;
    }

    .member-info__item-label {
      width: 140px;
    }

    .member-info__item-value {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .member-info-copy {
      cursor: pointer;
    }

    .member-status {
      &--active {
        color: $color-green;
      }
      &--initial {
        color: $color-orange;
      }
      &--inactive {
        color: $color-black;
      }
    }

    .member-info__item-value .theme-button {
      height: 30px;
      font-size: 12px;
      font-weight: bold;

      > span {
        margin: 0;
        min-height: auto;
        letter-spacing: normal;
      }

      &--green {
        background: linear-gradient(250deg, #3c763d, #3c763d 65%, #4d994e);
        box-shadow: none;
      }
    }
  }
}
.member-details {
  background: transparent;
  &.ant-card {
    box-shadow: none;
    border-radius: 0;
    > .ant-card-body {
      padding: 0;
    }
  }
  .member-content {
    position: relative;
    padding: 1.42857rem 2.14286rem;
    background-color: $color-white;
    border-radius: 6px;
  }
  &.member-comments,
  &.member-wishlist {
    .member-content {
      padding: 0;
      background-color: transparent;
      border-radius: 0;
    }

    .blk-empty {
      background-color: $color-white;
      padding-top: 1.42857rem;
      padding-bottom: 1.42857rem;
      border-radius: 6px;
    }
  }
}
.filter-member-orders {
  flex-wrap: wrap;
  .filter-item {
    &.filter-status {
      width: 200px;
    }
    &.sort {
      width: 120px;
    }
    label {
      font-weight: bold;
    }
    .ipt-order {
      background-color: $color-white;
    }
    .ant-btn,
    .ipt-order  {
      height: 40px;
    }
  }
}
.order-pagi {
  min-height: 30px;
}