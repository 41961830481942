.product-category-page {
  .url-path-input {
    display: flex;
    align-items: center;

    .ant-form-item {
      width: 100%;
    }

    .copy-button {
      margin-top: 8px;
      margin-left: 10px;
    }
  }
}