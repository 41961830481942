@import "../../../assets/sass/colors";

.menu-policies {
    margin: 0;
    padding: 0;
    font-size: 14px;
    line-height: 1.4;
    position: relative;
    z-index: 2;
    li {
        list-style: none;
        padding: 5px 10px;
        border-radius: 6px;
        cursor: pointer;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            background-color: $color-white-grey9;
        }
        &.active {
            background-color: $color-grey;
            .title {
                color: $color-red;
            }
        }
    }
    .title {
        font-size: 16px;
        margin: 0 0 5px 0;
    }
    .rank {
        padding-left: 17px;
        min-height: 20px;
    }
}
.member-policies-page {
    .ant-card {
        .ant-card-body {
            padding: 15px;
        }
    }
}
.member-rank-form {
    .title {
        margin-top: 0;
        font-size: 20px;
    }
    .desc {
        font-size: 14px;
        color: $color-mid-gray6;
        margin-top: -8px;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .msg {
        font-size: 14px;
        color: $color-red2;
        display: block;
    }
    .form-error {
        .ant-form-item-label {
            > label {
                color: $color-red2;
            }
        }
        .ant-upload {
            &.ant-upload-drag {
                border-color: $color-red2;
            }
        }
        .ql-toolbar,
        .ql-container {
            &.ql-snow {
                border-color: $color-red2;
            }
        }
    }
    .form-disabled {
        .ql-snow, .ant-upload  {
            pointer-events: none;
            background-color: rgba(204, 204, 204, 0.1);
            border-color: rgba(204, 204, 204, 0.5);
        }
    }
    .birthday-check {
        .ant-checkbox {
            + span {
                font-size: 16px;
                font-weight: 600;
            }
        }
    }
    .btn-add-coupons {
        &.error-btn {
            border-color: $color-red2;
            color: $color-red2;
        }
    }
    .frm-rank {
        z-index: 2;
    }
}

.coupons-lst {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
    .c-itm {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .num,
        .title {
            font-size: 18px;
            line-height: 22px;
        }
        .num {
            font-weight: 600;
            margin-right: 16px;
        }
        .title {
            text-decoration: underline;
        }
        .status-expired {
            color: $color-red2;
            margin-left: 8px;
        }
        .c-info, .c-buttons {
            flex: 0 0 auto;
        }
        .c-info {
            max-width: 75%;
        }
        .c-buttons {
            max-width: 25%;
        }
    }
}

.coupons-table {
    .coupon-cell__info {
        display: flex;
        flex-direction: column;
        gap: 10px;
        cursor: pointer;
        user-select: none;

        .coupon-info__title,
        .coupon-info__prefix {
            font-weight: 700;
        }

        .coupon-info__desc {
            color: #808080;
        }
    }

    .coupon-cell__total {
        .coupon-available {
            color: #bd0000;
            font-weight: 700;
            margin-bottom: 10px;
        }
        .coupon-total {
            color: #808080;
        }
    }

    .coupon-cell__discount {
        .coupon-discount {
            font-weight: bold;
            margin-bottom: 10px;
        }
        .coupon-discount-auto {
            color: #808080;
        }
    }

    .coupon-cell__date {
        .coupon__start-date {
            margin-bottom: 10px;
        }
        .coupon__start-date,
        .coupon__end-date {
            font-weight: 700;
        }
    }

    .coupon-status {
        &--active {
            color: #319f50;
        }

        &--draft {
            color: #308add;
        }

        &--expired {
            color: #db0002;
        }

        &--close {
            color: #494949;
        }
    }
}
