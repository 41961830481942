.sml-text {
    font-size: 12px;
    color: #ed253d;
    display: none;
    margin-top: 5px;
    &.shw {
        display: block;
    }
}
.datepicker-yearfrom {
    &.err {
        border-color: #ed253d;
    }
}