.attr-page {
  .attr-list-item {
    cursor: pointer;
    padding-left: 5px !important;
    padding-right: 5px !important;
    transition: all ease 0.2s;

    &:hover {
      background: #F4F5F8;
    }

    &.active {
      background: #F4F5F8;
    }
  }

  .add-group-btn-wrapper {
    width: 100%;
    margin-bottom: 15px;
  }
}

.contentAttrBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}