// Menu
.member-details-menu {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-end;
    flex-wrap: wrap;
    li {
      list-style: none;
      a {
        text-decoration: none;
        span {
          align-items: center;
          background: #ddd;
          box-shadow: none;
          display: inline-flex;
          justify-content: center;
          height: 18px;
          min-width: 18px;
          border-radius: 10px;
          padding: 0 8px;
        }
        &.active {
          font-weight: bold;
          span {
            background: linear-gradient(250deg, rgb(189, 0, 0), rgb(219, 0, 0) 65%, rgb(255, 23, 23));
            color: #fff;
          }
        }
      }
    }
}

// Filter status
.comments-status__tabs {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;

    .ant-btn, 
    .ant-btn.theme-button {
      width: 120px;
      height: 40px;
    }
  }