.manage-birthday-page {
    .btn-group {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 24px;
        position: absolute;
        top: 40px;
        right: 140px;
        z-index: 1;
    }
    .detail-toolbar__close-btn,
    .detail-toolbar__public-btn,
    .detail-toolbar__draft-btn,
    .detail-toolbar__download-btn {
        &.show-loading {
            .ant-btn-loading-icon {
                display: inline-block;
            }
        }
        .ant-btn-loading-icon {
            display: none;
        }
    }
    .detail-toolbar__public-btn,
    .detail-toolbar__download-btn {
        background-color: #319f50;
        color: #ffffff;
        border-color: #319f50;

        &:hover,
        &:focus {
            border-color: #319f50;
            color: #ffffff;
            background-color: #319f50;
        }
    }

    .detail-toolbar__draft-btn {
        background-color: #308add;
        color: #ffffff;
        border-color: #308add;

        &:hover,
        &:focus {
            border-color: #308add;
            color: #ffffff;
            background-color: #308add;
        }
    }

    .detail-toolbar__close-btn {
        background-color: #000;
        color: #ffffff;
        border-color: #000;

        &:hover,
        &:focus {
            border-color: #000;
            color: #ffffff;
            background-color: #000;
        }
    }
}

.manage-birthday-page {
    .coupon-tracking-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
        color: #000;
    }

    .coupon-general-info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .coupon-title-wrapper {
            font-size: 16px;
        }

        .coupon-title,
        .coupon-discount {
            font-weight: 600;
        }

        .coupon-title {
            margin-right: 4px;
        }

        .coupon-discount {
            color: #319f50;
        }

        .coupon-date-range {
            display: flex;
            align-items: center;
            gap: 24px;
            font-size: 14px;
        }

        .coupon-date-display {
            font-weight: 700;
        }
    }

    .coupon-status {
        &--active {
            color: #319f50;
        }

        &--draft {
            color: #308add;
        }

        &--expired {
            color: #db0002;
        }

        &--close {
            color: #494949;
        }
    }
}

.text-red {
    color: #db0002;
}

.select-cate-modal {
    .custom-modal {
        width: 50%;
    }
}