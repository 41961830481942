@import '../../assets/sass/colors.scss';

.orders-page {
    .ant-table {
        font-size: 16px;
        color: $color-black;
    }
    .order-info {
        a {
            text-decoration: none;
            &.locked {
                pointer-events: none;
                cursor: not-allowed;
            }
            &:hover {
                .num, .name {
                    text-decoration: underline;
                }
            }
        }
        .order-coupon {
            font-size: 14px;
            color: $color-mid-gray6;
            font-style: italic;
        }
    }
    .status {
        display: inline-block;
        margin-bottom: 5px;
        font-size: 14px;
    }
    .order-sort {
        .ant-select {
            width: 180px;
            height: 48px;
            .ant-select-selector {
                height: 48px;
            }
        }
        .bold {
            font-weight: 700;
            color: $color-black;
        }
    }
}
.orders-page {
    .vat-icon {
        font-size: 20px;
        &.icofont-check-circled {
            color: $color-green;
        }
    }
    .total-orders {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: $color-black;
        display: flex;
        align-items: center;
        gap: 15px;
        .total {
            span {
                color: $color-red;
                font-size: 24px;
            }
        }
    }
    .lbl {
        color: $color-mid-gray6;
    }
}
.order-detail-page {
    padding: 0;
    border: none;
    .member-content {
        position: relative;
        padding: 1.42857rem 2.14286rem;
        background-color: #fff;
        border-radius: 6px;
    }
}