@import '../variables';

$_border-width: 1px;
$_border-style: solid;
$_fs: 1rem;
$_ff: $main-ff;
$_fw: bold;
$_lh: $module-rem * 2 / $_fs;
$_tt: none;
$_padding: 0 ($module-rem * 2);
$_shape: 5px;
$_transition: $animation;
$_shadow: none;
$_shadow-hover: none;

$_lg-padding: 0 ($module-rem * 2.5);
$_lg-fs: ($_fs * 1.2);
$_lg-lh: $module-rem * 2 / $_lg-fs;

$_sm-padding: 0 ($module-rem / 2);
$_sm-fs: ($_fs * 0.8);
$_sm-lh: $module-rem / $_sm-fs;

.ant-btn {
  text-transform: uppercase;
  align-items: center;
  border-radius: $_shape;
  box-shadow: $_shadow;
  background-clip: padding-box;
  cursor: pointer;
  display: inline-flex;
  font-family: $_ff;
  font-size: $_fs;
  font-weight: $_fw;
  justify-content: center;
  height: 48px;
  outline: none;
  padding: $_padding;
  position: relative;
  text-align: center;
  text-transform: $_tt;
  touch-action: manipulation;
  transition: background 0.2s $_transition, border 0.2s $_transition, box-shadow 0.2s $_transition,
    color 0.2s $_transition, opacity 0.2s $_transition;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  will-change: background, border, box-shadow, color, opacity;
  -webkit-appearance: none;

  &.filter-button {
    color: #fff;
    background: #333;
    border-color: #333;

    &:hover {
      color: #333;
      background: #fff;
      border-color: #333;
    }

    &:active,
    &:focus {
      color: #fff;
      background: #333;
      border-color: #333;
    }
  }

  &.small {
    height: 28px;
    padding: 0 5px;
  }

  &.medium {
    height: 48px;
  }

  &.large {
    height: 68px;
    padding: 0 30px;
  }

  .anticon {
    min-height: 13px;
    height: 13px;
  }

  span {
    margin: 0 ($module-rem / 2);
    min-height: #{$_fs * $_lh};
    letter-spacing: 0.2px;

    &:empty {
      margin: 0;
    }
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.8;
    color: #dfdfdf;
  }

  &:hover,
  &:focus {
    color: #bd0000;
    border-color: #bd0000;
  }

  &:hover {
    box-shadow: $_shadow-hover;
  }

  &.ant-btn-danger {
    border: none;
  }

  &.ant-btn-primary {
    color: #bd0000;
    border-color: #bd0000;
    background-color: #fff;

    &:hover,
    &:focus {
      color: #4a505c;
      border-color: #4a505c;
    }
  }

  &.ant-btn-link {
    background: none;

    &:hover {
      background: none;
    }

    &:focus {
      background: none;
    }
  }

  &.ant-btn-lg {
    border-radius: 40px;
    height: 50px;
    font-size: $_lg-fs;
    padding: $_lg-padding;
    line-height: $_lg-lh;

    span {
      min-height: #{$_lg-fs * $_lg-lh};
    }
  }

  &.ant-btn-sm {
    font-size: $_sm-fs;
    padding: $_sm-padding;
    line-height: $_sm-lh;
    height: 30px;

    span {
      min-height: #{$_sm-fs * $_sm-lh};
    }
  }

  &.ant-btn-dashed {
    border: 1px dashed inherit;
  }

  &.theme-button {
    background: linear-gradient(250deg, rgb(189, 0, 0), #db0000 65%, #ff1717);
    border: none;
    color: #ffffff;
    height: 48px;
    box-shadow: 5px 5px 20px 0 rgba(204, 63, 48, 0.4);
    border-radius: 5px;

    &:hover {
      background: linear-gradient(250deg, rgb(235, 10, 10), #f32424 65%, #ff1717);
      border: none;
      box-shadow: 5px 5px 20px 0 rgba(204, 63, 48, 0.4);
    }
  }

  &.secondary-button {
    background: #000000;
    color: #fff;
    border-color: #000000;

    &:hover {
      background: #fff;
      color: #000000;
    }
  }

  &.success-button {
    background: #159981;
    color: #fff;
    border-color: #159981;

    &:hover {
      background: #fff;
      color: #159981;
    }
  }

  &.success-outline-button {
    background: #fff;
    color: #159981;
    border-color: #159981;

    &:hover {
      background: #159981;
      color: #fff;
    }
  }

  &.pending-button {
    background: #FF8B00;
    color: #fff;
    border-color: #FF8B00;

    &:hover {
      background: #fff;
      color: #FF8B00;
    }
  }

  &.locked-button {
    background: #494949;
    color: #fff;
    border-color: #494949;

    &:hover {
      background: #fff;
      color: #494949;
    }
  }

  &.ant-btn-circle {
    height: 32px;
    width: 32px;
    padding: 0 !important;
  }

  &.ant-btn-background-ghost {
    border: 1px solid currentColor;

    &:hover {
      &:not(.ant-btn-danger) {
        color: lighten(#2fa7ff, 10);
      }
    }
  }
}

.ant-btn-group {
  >.ant-btn:first-child:not(:last-child) {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  >.ant-btn:last-child:not(:first-child) {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}