.detail-page {
  .button-wrapper {
    text-align: right;
    margin-top: 20px;
  }
}
.bg-transparent {
  &.ant-card {
    background: transparent;
    box-shadow: none;
    > .ant-card-body {
      padding: 0;
    }
  }
}