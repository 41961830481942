.rider-header-footer__present {
  .header-block__wrapper {
    margin-bottom: 48px;
  }
  .block-title {
    text-align: center;
    font-size: 32px;
    font-weight: 500;
  }
  .ant-divider {
    margin: 48px 0px;
  }
}
