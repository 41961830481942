.add-news-modal__content {
  margin-top: 6px;

  .form-item__search {
    .ant-form-item-label {
      visibility: hidden;
    }
    .search-btn {
      width: 100%;
    }
  }

  .selected-news-label {
    margin-bottom: 8px;
    font-weight: 600;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }

  .save-button__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-top: 24px;
  }
}
