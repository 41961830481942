.display-menu-page {
  .tree-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .card-button {
      text-align: right;
    }
  }

  .sub-menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .ant-card {
      .ant-card-body {
        padding: 10px 15px;
      }
    }
  }

  .subMenuCol {
    background: #ddd;
  }
}

.subMenuTypeSelect {
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

ul {
  padding-left: 20px;
}

.menu-link {
  text-decoration: underline;
  cursor: pointer;
}