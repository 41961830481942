.custom-tag-wrapper {
  display: flex;
  flex-wrap: wrap;

  .site-tag-plus {
    background: #fff;
    border-style: dashed;
    height: 30px;
  }

  .edit-tag {
    user-select: none !important;
    margin-bottom: 5px;
  }

  .tag-input {
    width: 78px !important;
    margin-right: 8px !important;
    margin-bottom: 5px;
    vertical-align: top;
  }

  [data-theme="dark"] .site-tag-plus {
    background: transparent;
    border-style: dashed;
  }
}